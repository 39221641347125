import {isNumericString} from './helpers';

export function handleUpdat() {
  console.log("templates: handleUpdat");
  let ev = new Event("updateAll", { bubbles: false });
  window.dispatchEvent(ev);
}

export function handleFormChange(e, index, name, i, formFields, type) {
  console.log("templates: handleFormChange", type);
  if (type === "product") {
    formFields[index][name] = e.target.value;
  }
  if (type === "product-list") {
    formFields[index][name][i] = e.target.value;
  }
  let ev = new Event("hello", { bubbles: false });
  window.dispatchEvent(ev);
  window.removeEventListener("hello", ev);
}

export function textarea(data, name, lable) {
  //console.log("templates: textarea");
  let label
  if(!isNumericString(name)) {
    label = "";
  } else {
    label = lable;
  }

  return <div class="formEl formEl-textArea">
    <label class="formEl_lable" for={name}> {label}	</label>
    <textarea
      name={name}
      onChange={(e) => {
        data[name] = e.target.value;
        let ev = new Event("updateAll", { bubbles: false });
        window.dispatchEvent(ev);
      }}
      value={data[name]}
    />
  </div>
}

export function textBox(data, name, lable) {
 // console.log("templates: textBox");
  return <div class="formEl formEl-textBox">
    <label class="formEl_lable" for={name}>{lable}</label>
    <input
      id={name}
      type="text"
      name={name}
      onChange={(e) => {
        data[name] = e.target.value;
        let ev = new Event("updateAll", { bubbles: false });
        window.dispatchEvent(ev);
      }}
      value={data[name]}
    />
  </div>
}

export function rating(data, name, lable) {
  //console.log("templates: rating");
  return <div class="formEl formEl-radios">
    <label class="formEl_lable" for={name}> {lable}	</label>
    <div>
      {(() => {
          const options = [];
          for (let i = 1; i < 11; i++) {
            options.push(<input
              onChange={(e) => {
                data[name] = e.target.value;
                let ev = new Event("updateAll", { bubbles: false });
                window.dispatchEvent(ev);
              }}
              type="radio" name={name+"x"} value={i*.5} checked={data[name] == i*.5 }></input>);
            }
              options.push(<div><span>"Dont show"</span> <input
                onChange={(e) => {
                data[name] = e.target.value;
                let ev = new Event("updateAll", { bubbles: false });
                window.dispatchEvent(ev);
              }}
              type="radio" name={name+"x"} value="" checked={data[name] == "" }></input></div>);
          return options;
        })()}
    </div>
  </div>
}