
import React from 'react';
import { textarea, textBox, rating } from './scripts/editor/templates';
import { addProduct, removeProduct, sort} from './scripts/editor/controls';
import { adjustHeight } from './scripts/editor/helpers';
let productData = require('./data/products.json');

class App extends React.Component {
  static defaultProps = {
    testTopics: [{ title: "test" }, { title: "test" }]
  }

  constructor(props) {
    super(props)

    // Set initial state 
    this.state = {
      testName: "React js Test",
      topics: '',
      products: productData,
      product: '',
      page: '52'
    }

    // Binding this keyword 
    this.updateState = this.updateState.bind(this)
    this.updatePageIndex = this.updatePageIndex.bind(this)
  }

  saveProduct() {
    console.log("app:saveProduct");
    /*
    //let data = JSON.stringify(this.state.products);
    let data = {test:'test'};
    fetch("/saveProduct.php", {
      method: "POST",
      mode: "same-origin",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        "payload": data
      }),
    })*/

    fetch("/saveProduct.php", {
      method: "post",
      mode: "cors",
      headers: {
        "Content-Type": "application/json"
      },
      body: {data: "data"}
    })
      .then(response => {
        //return response.text(); // returns text that can used as html
        //return response.json();  // returns json object
      })
      .then(html => {
        //document.querySelector("#html_element").innerHTML = html;
      })
      .then(() => {
       // do something else;
      })
      .catch(function(err) {
        console.log("Failed to fetch page: ", err);
      });

  }

  componentDidMount() {
    console.log("app:componentDidMount");
    this.updateState();
    window.addEventListener("updateAll", this.updateState, false);
  }

  updateState() {
    console.log("app: updateState");
    this.setState({
      topics: this.drawNav(),
      product: this.drawProduct(),
    });
    adjustHeight();
  }

  drawNav() {
    return (
      <ul class="sortable-list">
        {this.state.products.map((product, i) => (
          <li key={product.id} data-position={i} class="item">
            <div></div>
            <span onClick={this.updatePageIndex} data-id={product.id}>{product.title}</span>
            <div>
              <button onClick={() => addProduct(this.state.products, i)}> + </button>
              <button onClick={() => removeProduct(this.state.products, i)}> - </button>
            </div>
            <div onDragStart={event => sort(event, this.state.products)} class="move dragIcon" draggable="true"></div>
          </li>
        ))}
      </ul>
    )
  }

  drawProduct() {
    console.log("app:drawProduct", this.state.page);
    for (let i = 0; i < this.state.products.length; i++) {
      if (this.state.page == this.state.products[i].id) {
        return (
          <div>
            <button onClick={ () => this.saveProduct()}> save </button>
            {this.state.products[i].title}
            <div class="formSection">{textBox(this.state.products[i], "title", "Title")}</div>
            <div class="formSection">{textBox(this.state.products[i], "keywords", "Keywords")}</div>
            <div class="formSection">{textBox(this.state.products[i], "productImage", "image")}</div>
            <div class="formSection">{rating(this.state.products[i], "fun", "Fun")}</div>
            <div class="formSection">{rating(this.state.products[i], "noise", "Noise")}</div>
            <div class="formSection">{rating(this.state.products[i], "mess", "Mess")}</div>
            <div class="formSection">{textarea(this.state.products[i], "shortVerdict", "Short description")}</div>
            <div class="formSection">{textarea(this.state.products[i], "text", "Description")}</div>
            <div class="formSection">{textarea(this.state.products[i], "verdict", "Our bunnies verdict")}</div>
            <div class="formSection">{textarea(this.state.products[i], "bunnyProofing", "Bunny proofing")}</div>

            <div class="section sortable-list">
            <div class="sectionTitle">Features</div>
              {(() => {
                const options = [];
                for (let j = 0; j < this.state.products[i].features.length; j++) {
                  options.push(<div data-position={j} class="product formSection">
                    {textarea(this.state.products[i].features, j)}
                    <div>
                      <button onClick={ () => addProduct(this.state.products[i].features, j)}> + </button>
                      <button onClick={ () => removeProduct(this.state.products[i].features, j)}> - </button>
                    </div>
                    <div onDragStart={event => sort(event, this.state.products)} class="move dragIcon" draggable="true"></div>
                  </div>)
                }
                return options;
              })()}
            </div>
          </div>
        )
      }
    }
  }

  updatePageIndex(e) {
    let id = e.target.dataset.id;
    this.state.page = id;
    this.setState({
      product: this.drawProduct(),
      page: id
    })
    adjustHeight();
  }

  render() {
    return (
      <div className="App" class="wrapper">
        <div class="aside">
          {this.state.topics}
        </div>
        <div class="page">
          {this.state.product}
        </div>
      </div>
    )
  }

}

export default App;