import { getHighestId} from './helpers';

export function sort(event, data) {
  console.log("app: sort");
  var fromIndex;
  var toIndex;

  var dragIconElement = event.target.parentNode;
  var dragListElement = dragIconElement.closest('.sortable-list');

  dragIconElement.classList.add("dragging");
  dragIconElement.addEventListener("dragend", () => dragIconElement.classList.remove("dragging"));

  function endandupdate() {
    

      var element = data[fromIndex];
      data.splice(fromIndex, 1);
      data.splice(toIndex, 0, element);
      let ev = new Event("updateAll", { bubbles: false });
      window.dispatchEvent(ev);
  }

  const initSortableList = (ev) => {
    ev.preventDefault();
    const draggingItem = dragListElement.querySelector(".dragging");
    let siblings = [...dragListElement.querySelectorAll(":scope > *:not(.dragging)")];
    let nextSibling = siblings.find(sibling => {
      return ev.clientY <= sibling.offsetTop + sibling.offsetHeight / 2;
    });
    if (draggingItem) {
      if (nextSibling !== undefined) {
        fromIndex = draggingItem.dataset.position;
        toIndex = nextSibling.dataset.position;
        if(fromIndex <= toIndex) {
          toIndex -=1;
        }
      }
      dragListElement.insertBefore(draggingItem, nextSibling);
    }
  }

  dragListElement.addEventListener("dragover", initSortableList);
  dragIconElement.addEventListener("dragend", endandupdate);
  dragListElement.addEventListener("dragenter", e => e.preventDefault());
}


export function sortx(e, data) {
  console.log("sort");
  var sortableList = e.target.closest('.sortable-list');
  var fromIndex;
  var toIndex;
  for (const item of sortableList.children) {
    item.querySelector(".move").addEventListener("dragstart", () => {
      setTimeout(() => item.classList.add("dragging"), 0);
    });
    item.addEventListener("dragend", () => item.classList.remove("dragging"));
  };

  function endandupdate() {
      var element = data[fromIndex];
      data.splice(fromIndex, 1);
      data.splice(toIndex, 0, element);
      let ev = new Event("updateAll", { bubbles: false });
      window.dispatchEvent(ev);
  }

  const initSortableList = (ev) => {
    ev.preventDefault();
    const draggingItem = document.querySelector(".dragging");

    let siblings = [...sortableList.querySelectorAll(":scope > *:not(.dragging)")];
    let nextSibling = siblings.find(sibling => {
      return ev.clientY <= sibling.offsetTop + sibling.offsetHeight / 2;
    });
    if (draggingItem) {
      sortableList.insertBefore(draggingItem, nextSibling);
      if (nextSibling !== undefined) {
        fromIndex = draggingItem.dataset.position;
        toIndex = nextSibling.dataset.position;
      }
    }
  }

  sortableList.addEventListener("dragover", initSortableList);
  sortableList.addEventListener("dragend", endandupdate);
  sortableList.addEventListener("dragenter", e => e.preventDefault());
}

export const removeProduct = (data, index) => {
  console.log("app: removeProduct");
  data.splice(index, 1);
  let ev = new Event("updateAll", { bubbles: false });
  window.dispatchEvent(ev);
}

export const addProduct = (data, index) => {
  console.log("app: addProduct");
  let element = {
    "id": getHighestId(data),
    "title": "New page",
    "text": "",
    "bunnyProofing": "",
    "shortVerdict": "",
    "verdict": "",
    "fun": "",
    "valueForMoney": "",
    "mess": "",
    "productImage": "book",
    "features": [
      ""
    ],
    "keywords": "",
    "shortVerdictTitle": "",
    "from": "",
    "pros": [
      ""
    ],
    "cons": [
      ""
    ],
    "noise": ""
  }
  data.splice(index, 0, element);
  let ev = new Event("updateAll", { bubbles: false });
  window.dispatchEvent(ev);
}
