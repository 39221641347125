export function adjustHeight() {
	console.log("adjustHeight");
	setTimeout(function(){
		let textAreas = document.getElementsByTagName('textarea');
		for (let i = 0; i < textAreas.length; i++) {
			textAreas[i].style.height = (textAreas[i].scrollHeight > textAreas[i].clientHeight) ? (textAreas[i].scrollHeight)+"px" : "50px";
		};
}, 10);
}

export function getHighestId(data) {
	var max = 0;
	data.map((product, index) => {
		let heighestId = parseInt(product.id);
		if (heighestId > max){
			max = heighestId;
		}
	});
	return max + 1;
} 

export function isNumericString(input) {  
	return typeof input === 'string' && !Number.isNaN(input)
  }